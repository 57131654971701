import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoadingBarModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vonniqc-fe';

  nonce: any = ''

  constructor(private router: Router) {
    this.nonce = (
      document.querySelector('meta[name="custom_nonce"]') as HTMLMetaElement
    )?.content;
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.url;
        const chatbotIframe = document.getElementById('fc_frame');
        if (currentRoute.includes('/auth')) {
          chatbotIframe?.style.setProperty('display', 'none');
        } else {
          this.loadChatbotScript();
          chatbotIframe?.style.setProperty('display', 'block');
        }
      }
    });
  }

  private loadChatbotScript(): void {
    if(!document.getElementById('chatbotScript')) {
    const script = document.createElement('script');
    script.id = 'chatbotScript';
    script.src = '//fw-cdn.com/11632644/4278238.js';
    script.setAttribute('chat', 'true');
    script.setAttribute('nonce', this.nonce);
    script.setAttribute('widgetId', '5d943837-3d1b-4c16-b26f-8f1478616658');
    script.async = true;
    const chatbotIframe = document.getElementById('fc_frame');
    chatbotIframe?.style.setProperty('display', 'block');
    document.body.appendChild(script);
    }
  }
}
