import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

/* Components */
import { PageNotFoundComponent } from './error/page-not-found/page-not-found.component';
import { SomethingWentWrongComponent } from './error/something-went-wrong/something-went-wrong.component';

import { PublicGuard } from './core/guards/public.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { OrderReviewComponent } from './orders/order-review/order-review.component';
import { OrderMessagesComponent } from './orders/order-review/order-messages/order-messages.component';
import { OrderPreviewComponent } from './orders/order-review/order-preview/order-preview.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule ), canActivate: [PublicGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard],
  },
  {
    path: 'appraiser-link/order-preview/:orderId/:token',
    component: OrderPreviewComponent,
  },
  {
    path: 'appraiser-link/:orderId/:token',
    component: OrderReviewComponent,
  },
  {
    path: 'appraiser-link/:orderId/:token/messages',
    component: OrderMessagesComponent,
  },
  {
    path: 'error',
    component: SomethingWentWrongComponent,
    data: { title: 'Something Went Wrong' },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: 'Page Not Found' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
